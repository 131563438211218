export const ENVIRONMENT = {
  nodeEnv: process.env.NODE_ENV,
  version: process.env.NEXT_PUBLIC_VERSION || "",
  domain: process.env.NEXT_PUBLIC_DOMAIN || "",
  projectDomain: process.env.NEXT_PUBLIC_CONSOLE_DOMAIN || "",
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || "",
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "",
  },
};
