import { AppProps, AppType } from "next/app";
import Head from "next/head";
import "../Setup";

const App: AppType = (props: AppProps) => {
  const { Component } = props;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no"
        />
        <meta key="description" name="description" content="Stai" />
        <title>Stai</title>
      </Head>
      <Component {...props.pageProps} />
    </>
  );
};

export default App;
